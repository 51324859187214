<template>
  <div class="b-absensi pb-5">
    <b-row no-gutters class="pt-3">
      <div class="container-info px-3">
        <div class="hak_cuti">
          <div class="text-black total">
            <span>{{ form.data.hak_cuti }}</span>
          </div>
          <div class="desc">
            <span>Hak Cuti</span>
          </div>
        </div>
        <div class="hak_cuti_lalu">
          <div class="text-black total">
            <span>{{ form.data.hak_cuti_lalu }}</span>
          </div>
          <div class="desc">
            <span>Hak Cuti Lalu</span>
          </div>
        </div>
        <div class="hak_ijin">
          <div class="text-black total">
            <span>{{ form.data.hak_ijin }}</span>
          </div>
          <div class="desc">
            <span>Hak Izin</span>
          </div>
        </div>
      </div>
    </b-row>
    <b-row no-gutters class="m-0">
      <b-col sm="12" class="p-3">
        <router-link to="/absensi/form">
          <b-button block class="w-100 f-11 bold button-sag"
            >Ajukan Absen</b-button
          >
        </router-link>
      </b-col>
    </b-row>
    <b-row no-gutters class="m-0">
      <b-col xs="6" class="p-3">
        <b-form-group
          label="Pilih Bulan"
          class="f-13"
          style="font-weight: bold"
        >
          <b-form-select
            :options="opt.bulan"
            class="w-100 f-13"
            v-model="absensi.filter.bulan"
            @change="getHistoriAbsensi()"
          />
        </b-form-group>
      </b-col>
      <b-col xs="6" class="p-3">
        <b-form-group
          label="Pilih Tahun"
          class="f-13"
          style="font-weight: bold"
        >
          <b-form-select
            :options="opt.tahun"
            class="w-100 f-13"
            v-model="absensi.filter.tahun"
            @change="getHistoriAbsensi()"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters class="m-0">
      <b-col sm="12" class="p-3">
        <div class="bd-card rounded-3 bg-light">
          <div class="p-3 d-flex align-items-center f-14 bold">
            <b-icon icon="journal-check" class="text-primary mr-2" />
            <i class="bi bi-journal-check"></i> Histori Absensi
          </div>
          <div class="page-faq p-1">
            <div class="accordion" role="tablist">
              <div
                class="d-flex justify-content-center align-items-center flex-column p-5"
                v-if="form.loading"
              >
                <b-spinner
                  class="f-12"
                  type="grow"
                  label="Spinning"
                  style="background-color: #159895"
                ></b-spinner>
                <span class="f-11 mt-1 bold">Loading....</span>
              </div>
              <div v-else>
                <!-- DISETUJUI -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle.accordion-1
                      class="text-left f-12 bold bg-card-disetujui"
                    >
                      <div class="float-left">
                        <b-icon
                          icon="patch-check-fill"
                          class="text-white pr-1"
                          style="fill: #159895"
                        >
                        </b-icon>
                        Pengajuan Di setujui
                      </div>
                      <div class="float-right">
                        <span
                          class="f-10"
                          style="
                            padding: 2px 10px 2px 10px;
                            color: white;
                            border-radius: 50px;
                            background-color: #159895;
                          "
                          >{{ jumlahDisetujui }}</span
                        >
                      </div>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div
                      class="bd-card-item p-2 mx-1 mt-1 text-white mb-0 bg-card-cus-content"
                      style="border-radius: 5px"
                      v-for="(abs, absidx) in absensi.disetujui"
                      :key="`abs-${absidx}`"
                    >
                      <!-- kategori cuti -->
                      <div class="d-flex justify-content-between">
                        <h3 class="m-0 p-0 f-12 bold">
                          {{ abs.kategori_cuti }}
                        </h3>
                        <label class="f-11">{{ `${abs.hari} Hari` }}</label>
                      </div>
                      <!-- keterangan -->
                      <span class="f-11">{{ abs.keterangan }}</span>
                      <!-- hari -->
                      <div class="d-flex justify-content-between mt-3">
                        <h6 class="m-0 p-0 f-11">
                          {{ dateFormat(abs.tgl_cuti) }} s/d
                          {{ dateFormat(abs.tgl_cuti2) }}
                        </h6>
                        <!-- tipe cuti/ijin -->
                        <span class="f-11">{{ abs.tipe }}</span>
                      </div>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- END DISETUJUI -->

                <!-- MENUNGGU VERIFIKASI -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle.accordion-2
                      class="text-left f-12 bold bg-card-pending"
                    >
                      <div class="float-left">
                        <b-icon
                          icon="patch-minus-fill"
                          class="text-white pr-1"
                          style="fill: #e55807"
                        >
                        </b-icon>
                        Menunggu verifikasi
                      </div>
                      <div class="float-right">
                        <span
                          class="f-10"
                          style="
                            padding: 2px 10px 2px 10px;
                            color: white;
                            border-radius: 50px;
                            background-color: #e55807;
                          "
                          >{{ jumlahPending }}</span
                        >
                      </div>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div
                      class="bd-card-item p-2 mx-1 mt-1 text-white mb-1 bg-card-cus-pending"
                      style="border-radius: 5px"
                      v-for="(abs, absidx) in absensi.pending"
                      :key="`abs-${absidx}`"
                    >
                      <!-- kategori cuti -->
                      <div class="d-flex justify-content-between">
                        <h3 class="m-0 p-0 f-12 bold">
                          {{ abs.kategori_cuti }}
                        </h3>
                        <label class="f-11">{{ `${abs.hari} Hari` }}</label>
                      </div>
                      <!-- keterangan -->
                      <span class="f-11">{{ abs.keterangan }}</span>
                      <!-- hari -->
                      <div class="d-flex justify-content-between mt-3">
                        <h6 class="m-0 p-0 f-11">
                          {{ dateFormat(abs.tgl_cuti) }} s/d
                          {{ dateFormat(abs.tgl_cuti2) }}
                        </h6>
                        <!-- tipe cuti/ijin -->
                        <span class="f-11">{{ abs.tipe }}</span>
                      </div>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- END MENUNGGU VERIFIKASI -->

                <!-- DITOLAK -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle.accordion-3
                      class="text-left f-12 bold bg-card-ditolak"
                    >
                      <div class="float-left">
                        <b-icon
                          icon="patch-exclamation-fill"
                          class="text-white pr-1 f-13 bold icon-tolak"
                        >
                        </b-icon>
                        Pengajuan Ditolak
                      </div>
                      <div class="float-right">
                        <span class="f-10 span-tolak">{{ jumlahDitolak }}</span>
                      </div>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div
                      class="bd-card-item p-2 mx-1 mt-1 text-white mb-1 bg-card-cus-ditolak"
                      style="border-radius: 5px"
                      v-for="(abs, absidx) in absensi.ditolak"
                      :key="`abs-${absidx}`"
                    >
                      <!-- kategori cuti -->
                      <div class="d-flex justify-content-between">
                        <h3 class="m-0 p-0 f-12 bold">
                          {{ abs.kategori_cuti }}
                        </h3>
                        <label class="f-11">{{ `${abs.hari} Hari` }}</label>
                      </div>
                      <!-- keterangan -->
                      <span class="f-11">{{ abs.keterangan }}</span>
                      <!-- hari -->
                      <div class="d-flex justify-content-between mt-3">
                        <h6 class="m-0 p-0 f-11">
                          {{ dateFormat(abs.tgl_cuti) }} s/d
                          {{ dateFormat(abs.tgl_cuti2) }}
                        </h6>
                        <!-- tipe cuti/ijin -->
                        <span class="f-11">{{ abs.tipe }}</span>
                      </div>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- DITOLAK -->

                <!-- DIANULIR -->
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle.accordion-4
                      class="text-left f-12 bold bg-card-dianulir"
                    >
                      <div class="float-left">
                        <b-icon
                          icon="x-circle-fill"
                          class="text-white pr-1"
                          style="fill: #482121"
                        >
                        </b-icon>
                        Pengajuan Dianulir HR
                      </div>
                      <div class="float-right">
                        <span
                          class="f-10"
                          style="
                            padding: 2px 10px 2px 10px;
                            color: white;
                            border-radius: 50px;
                            background-color: #482121;
                          "
                          >{{ jumlahDianulir }}</span
                        >
                      </div>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-4"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div
                      class="bd-card-item p-3 mx-1 mt-1 text-white mb-0 bg-card-cus-dianulir"
                      style="border-radius: 5px"
                      v-for="(abs, absidx) in absensi.dianulir"
                      :key="`abs-${absidx}`"
                    >
                      <!-- kategori cuti -->
                      <div class="d-flex justify-content-between">
                        <h3 class="m-0 p-0 f-12 bold">
                          {{ abs.kategori_cuti }}
                        </h3>
                        <label class="f-11">{{ `${abs.hari} Hari` }}</label>
                      </div>
                      <!-- keterangan -->
                      <span class="f-11">{{ abs.keterangan }}</span>
                      <!-- hari -->
                      <div class="d-flex justify-content-between mt-3">
                        <h6 class="m-0 p-0 f-11">
                          {{ dateFormat(abs.tgl_cuti) }} s/d
                          {{ dateFormat(abs.tgl_cuti2) }}
                        </h6>
                        <!-- tipe cuti/ijin -->
                        <span class="f-11">{{ abs.tipe }}</span>
                      </div>
                    </div>
                  </b-collapse>
                </b-card>
                <!-- DIANULIR-->
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style>
/* INFO HAK CUTI DAN HAK IZIN */
.container-info {
  gap: 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* HAK CUTI */
.hak_cuti {
  display: flex;
  padding: 8px 0 8px 0;
  gap: 10px;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #2d033b;
  border-radius: 5px;
}
.hak_cuti .total {
  padding: 2px;
  width: 30px;
  font-size: 13px;
  font-weight: bolder;
  text-align: center;
  color: #2d033b;
  background-color: #eeeeee;
  border-radius: 5px;
}
.hak_cuti .desc {
  font-size: 10px;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #eeeeee;
  text-transform: uppercase;
}
/* END HAK CUTI */

/* HAK CUTI LALU */
.hak_cuti_lalu {
  display: flex;
  padding: 8px 0 8px 0;
  gap: 10px;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #4c0033;
  border-radius: 5px;
}
.hak_cuti_lalu .total {
  padding: 2px;
  width: 30px;
  font-size: 13px;
  font-weight: bolder;
  text-align: center;
  color: #4c0033;
  background-color: #eeeeee;
  border-radius: 5px;
}
.hak_cuti_lalu .desc {
  width: 40%;
  font-size: 10px;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #eeeeee;
  text-transform: uppercase;
}
/* END HAK CUTI LALU */
/* HAK IZIN */
.hak_ijin {
  display: flex;
  padding: 8px 0 8px 0;
  gap: 10px;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #005555;
  border-radius: 5px;
}
.hak_ijin .total {
  padding: 2px;
  width: 30px;
  font-size: 13px;
  font-weight: bolder;
  text-align: center;
  color: #005555;
  background-color: #eeeeee;
  border-radius: 5px;
}
.hak_ijin .desc {
  font-size: 10px;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #eeeeee;
  text-transform: uppercase;
}
/* END HAK IZIN */

.bg-card-grey {
  border: none;
  color: black;
  background-color: #b2b2b2;
}
.bg-card-cus {
  background-color: #ecf9ff;
}

/* DISETUJUI */
.bg-card-disetujui {
  border: none;
  background-color: white;
  color: #159895;
}
.bg-card-cus-content {
  border: none;
  background-color: #17adaa;
}
.bg-card-disetujui:hover {
  border: none;
  background-color: #17adaa;
  color: white;
}
.bg-card-disetujui:focus {
  border: none;
  background-color: #17adaa;
  color: white;
}
/* END DISETUJUI */
/* PENDING */
.bg-card-pending {
  border: none;
  background-color: white;
  color: #e55807;
}
.bg-card-cus-pending {
  border: none;
  background-color: #f58840;
}
.bg-card-pending:hover {
  border: none;
  background-color: #f58840;
  color: white;
}
.bg-card-pending:focus {
  border: none;
  background-color: #f58840;
  color: white;
}

/* END PENDING */
/* DITOLAK */
.bg-card-ditolak {
  border: none;
  background-color: white;
  color: #b70404;
}
.bg-card-cus-ditolak {
  border: none;
  background-color: #bd4b4b;
}
.icon-tolak {
  fill: #b70404;
}
.span-tolak {
  padding: 2px 10px 2px 10px;
  color: white;
  border-radius: 50px;
  background-color: #b70404;
}
.bg-card-ditolak:focus {
  border: none;
  background-color: #bd4b4b !important;
  color: white !important;
}
.bg-card-ditolak:hover {
  border: none;
  background-color: #bd4b4b !important;
  color: white !important;
}
/* END DITOLAK */

/* DIANULIR */
.bg-card-dianulir {
  border: none;
  background-color: white;
  color: #482121;
}
.bg-card-cus-dianulir {
  border: none;
  background-color: #7a3e3e;
}
.bg-card-dianulir:hover {
  border: none;
  background-color: #7a3e3e;
  color: white;
}
.bg-card-dianulir:focus {
  border: none;
  background-color: #7a3e3e !important;
  color: white;
}
/* END DIANULIR */
.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.button-sag {
  background-color: #159895;
  border: none;
  text-transform: uppercase;
}
.button-sag:hover {
  background-color: #159895;
}
</style>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      jumlahDisetujui: 0,
      jumlahPending: 0,
      jumlahDitolak: 0,
      jumlahDianulir: 0,
      form: {
        data: Object,
        loading: false,
      },
      absensi: {
        disetujui: [],
        pending: [],
        ditolak: [],
        dianulir: [],
        filter: {
          bulan: new Date().getMonth() + 1,
          tahun: new Date().getFullYear(),
        },
      },
      opt: {
        bulan: [
          {
            value: "00",
            text: "Pilih Bulan",
          },
          {
            value: "01",
            text: "Januari",
          },
          {
            value: "02",
            text: "Februari",
          },
          {
            value: "03",
            text: "Maret",
          },
          {
            value: "04",
            text: "April",
          },
          {
            value: "05",
            text: "Mei",
          },
          {
            value: "06",
            text: "Juni",
          },
          {
            value: "07",
            text: "Juli",
          },
          {
            value: "08",
            text: "Agustus",
          },
          {
            value: "09",
            text: "September",
          },
          {
            value: "10",
            text: "Oktober",
          },
          {
            value: "11",
            text: "November",
          },
          {
            value: "12",
            text: "Desember",
          },
        ],
        tahun: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    dateFormat(date) {
      let theDate = new Date(date);
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      let day = theDate.getDay();
      let d = theDate.getDate();
      let m = theDate.getMonth() + 1;
      let y = theDate.getFullYear();
      return `${days[day]}, ${d}-${m}-${y}`;
    },
    async getHakAbsensi() {
      this.form.loading = true;
      let url = `presence/hak_cuti_ijin?nik=${this.user.data.nik}`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      if (req.data.status) {
        this.form.loading = false;
        this.form.data = req.data.data;
      } else {
        this.form.loading = false;
      }
    },
    async getHistoriAbsensi() {
      this.form.loading = true;
      let url = `presence/list_presence`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.absensi.filter) {
        payload.append(key, this.absensi.filter[key]);
      }
      payload.append(
        "periode",
        `${this.absensi.filter.bulan}-${this.absensi.filter.tahun}`
      );
      payload.append("nik", this.user.data.nik);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let disetujui = [];
        let pending = [];
        let ditolak = [];
        let dianulir = [];
        req.data.msg.map((x) => {
          if (x.status == "1") {
            disetujui.push(x);
          } else if (x.status == "0") {
            pending.push(x);
          } else if (x.status == "2") {
            ditolak.push(x);
          } else {
            dianulir.push(x);
          }
        });
        this.absensi.disetujui = disetujui;
        this.absensi.pending = pending;
        this.absensi.ditolak = ditolak;
        this.absensi.dianulir = dianulir;
        this.jumlahDisetujui = this.absensi.disetujui.length;
        this.jumlahPending = this.absensi.pending.length;
        this.jumlahDitolak = this.absensi.ditolak.length;
        this.jumlahDianulir = this.absensi.dianulir.length;

        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
  },
  mounted() {
    this.getHistoriAbsensi();
    this.getHakAbsensi();
    this.absensi.filter.bulan = this.pad(this.absensi.filter.bulan, 2);
    let thisYear = new Date().getFullYear() + 1;
    for (let x = thisYear; x > 1990; x--) {
      let tahun = {
        value: x,
        text: x,
      };
      this.opt.tahun.push(tahun);
    }
  },
};
</script>
